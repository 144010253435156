import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=eb71d0fa&class=true&"
import script from "./Footer.vue?vue&type=script&lang=js&"
export * from "./Footer.vue?vue&type=script&lang=js&"
import style0 from "./Footer.vue?vue&type=style&index=0&id=eb71d0fa&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UIOpeningTimes: require('/opt/build/repo/components/UI/OpeningTimes.vue').default,UISearch: require('/opt/build/repo/components/UI/Search.vue').default})
